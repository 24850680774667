input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid initial;
  -webkit-text-fill-color: initial;
  -webkit-box-shadow: 0 0 0px 1000px initial inset;
  transition: background-color 5000s ease-in-out 0s;
}

:root{
  --primary-color:#f8f9fa;
  --secondary-color:#FFB100;
  --text-color:#1C1A1A;
}

body{
  margin:0;
  background:var(--primary-color);
  color:var(--text-color)
}

#root{
  display:flex;
  min-height:100vh;
}

main{
  width:100%;
  padding:40px 20px 20px;
}