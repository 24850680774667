.menu-wrapper{
  margin-left: -250px;
  transition: 0.4s;
  .menu-burger{
    position: fixed;
    top:10px;
    left:10px;
  }
  .app-navigation{
    width:230px;
    padding:10px;
    margin-top: 40px;
    display:flex;
    font-size: 14px;
    .menu-container{
      margin:0 auto;
      width:200px;
      ul{
        list-style: none;
        padding:0;
        li{
          a{
            text-decoration: none;
            color:var(--text-color);
          }
          .disable-link{
            pointer-events: none;
            color:lightgrey;
          }
          .accordion{
            height:0px;
            transition-duration: 0.4s;
            overflow: hidden;
            padding-left: 16px;
            li{
              margin-top: 10px;
            }
            li:last-child{
              padding-bottom: 16px;
            }
          }
          .menu-parent{
            display: flex;
            align-items: center;
            padding:10px 16px;
            cursor:pointer;
            &>*{
              pointer-events: none;
            }
            span{
              margin:0 auto 0 10px;
            }
            .menu-icon{
              width:18px;
              height:18px;
            }
          }
        }
      }
      &>div{
        text-transform: uppercase;
        font-weight: bold;
        padding-left: 16px;
      }
    }
  }
}

.menu-wrapper-open{
  margin-left: 0;
}

.burger {
  width: 30px;
  height: 25px;
  position: absolute;
  top: 10px;
  left: 20px;
  border-radius: 4px;
  z-index: 100001;
  outline:none;
  transition:0.4s;
}
.burger-sticky{
  top:15px;
}
.burger span {
  position: relative;
  margin-top: 9px;
  margin-bottom: 9px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -15px;
  margin-top: -1.5px;
}
.burger span, .burger span::before, .burger span::after {
  display: block;
  width: 30px;
  height: 3px;
  background-color: #000;
  outline: 1px solid transparent;
  -webkit-transition-property: background-color, -webkit-transform;
  -moz-transition-property: background-color, -moz-transform;
  -o-transition-property: background-color, -o-transform;
  transition-property: background-color, transform;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
}
.burger span::before, .burger span::after {
  position: absolute;
  content: "";
}
.burger span::before {
  top: -9px;
}
.burger span::after {
  top: 9px;
}
.burger.clicked span {
  background-color: transparent;
}
.burger.clicked span::before {
  -webkit-transform: translateY(9px) rotate(45deg);
  -moz-transform: translateY(9px) rotate(45deg);
  -ms-transform: translateY(9px) rotate(45deg);
  -o-transform: translateY(9px) rotate(45deg);
  transform: translateY(9px) rotate(45deg);
}
.burger.clicked span::after {
  -webkit-transform: translateY(-9px) rotate(-45deg);
  -moz-transform: translateY(-9px) rotate(-45deg);
  -ms-transform: translateY(-9px) rotate(-45deg);
  -o-transform: translateY(-9px) rotate(-45deg);
  transform: translateY(-9px) rotate(-45deg);
}

.burger:hover {
  cursor: pointer;
}